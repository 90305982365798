/* eslint-disable arrow-parens */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import React from 'react';
import {
  graphql,
  withPrefix,
  Link,
} from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Image from 'gatsby-image';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SEO from '../components/SEO';
import Layout from '../layouts/index';

const CarouselSettings = (name) => ({
  initialSlide: 0,
  infinite: false,
  speed: 500,
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  lazyLoad: true,
  afterChange: (current) => (
    trackCustomEvent({
      category: `Student Parent - ${name}`,
      action: "Click",
      label: `Student Journey`,
      value: current,
    })
  ),
});

const MobileCarouselSettings = (name) => ({
  ...CarouselSettings(name),
  lazyLoad: true,
  afterChange: (current) => (
    trackCustomEvent({
      category: `Student Parent - ${name}`,
      action: "Click",
      label: `Mobile Student Journey`,
      value: current,
    })
  ),
});

const Service = ({ data, location }) => {
  const { name } = data.markdownRemark.frontmatter;
  const { html, excerpt } = data.markdownRemark;
  const mobileImages = data.mobileImages.edges;
  const desktopImages = data.desktopImages.edges;
  return (
    <Layout bodyClass="page-story" location={location}>
      <SEO
        title={name}
        meta={[
          { name: 'description', content: excerpt },
        ]}
      />
      { desktopImages && (
        <section className="carousel-desktop">
          <div className="container pt-2 pt-md-5">
            <div className="row">
              <div className="col-12"/>
            </div>
          </div>
          <Slider {...CarouselSettings(name)}>
            {desktopImages.map((edge, idx) => (
              <Image key={`${edge.node.name}-${idx}`} fluid={edge.node.childImageSharp.fluid} alt={edge.node.name} />
            ))}
          </Slider>
        </section>
      )}
      { mobileImages && (
        <section className="carousel-mobile">
          <Slider {...MobileCarouselSettings(name)}>
            {mobileImages.map((edge, idx) => (
              <Image key={`${edge.node.name}-${idx}`} fluid={edge.node.childImageSharp.fluid} alt={edge.node.name} />
            ))}
          </Slider>
        </section>
      )}
      <div className="container py-2 py-md-4">
        <div className="row justify-content-start">
          <div className="col-12 mx-auto">
            <div className="story story-single">
              <h4 className="story-title">{`Meet ${name}`}</h4>
              <div className="story-content" dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
          <Link
            className="story-back-link mx-1 py-4"
            to="/about-risers#risers"
            onClick={() => {
              trackCustomEvent({
                category: `Student Parent - ${name}`,
                action: "Click",
                label: `Back to Student Parents`,
              });
            }}
          >
            <img className="chevron-left" src={withPrefix('/icons/chevron-left.svg')} alt="chevron left" role="presentation" />
            <span>BACK TO RISERS</span>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!, $mobileSlidesRegex: String!, $desktopSlidesRegex: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        name
        path
        image
        hero
        color
        desktopSlides
        mobileSlides
      }
      html
      excerpt
    }
    mobileImages: allFile(filter: {name: {regex: $mobileSlidesRegex}}, sort: {fields: name}) {
      edges {
        node {
          name
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    desktopImages: allFile(filter: {name: {regex: $desktopSlidesRegex}}, sort: {fields: name}) {
      edges {
        node {
          name
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default Service;
